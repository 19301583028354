import { isAfter, isBefore, isSameDay, isTomorrow } from "date-fns";
import database from "../database/DataBaseConfig";
import Alert from "../entities/Alert";
import Associated from "../entities/Associated";
import SystemAlert from "../entities/SystemAlert";
import DeliveryDaysInteractor from "./delivery/DeliveryDaysInteractor";
import { isInLatePaymentState } from "./PaymentInteractor";



export async function getActiveAlerts():Promise<Alert[]>{
  let alerts = await database.alert_db.all();
  let today = new Date();

  return alerts.filter((alert)=>{
    return isBefore(today, alert.endDate) &&
        isAfter(today, alert.initialDate)
  })
}

type activeSystemAlertFilter = (alert:SystemAlert, associated:Associated) => boolean;

export default async function getSystemAlertsFor(associated_id:string):Promise<SystemAlert[]>{
  let associated = await database.associated_db.find(associated_id);
  if(!associated) return []
  
  let filters: activeSystemAlertFilter[] = [
    deliverySystemAlert, birthdaySystemAlert, latePaymentSystemAlert
  ]
  let alerts = await database.systemalert_db.all();

  for(let alert_filter of filters){
    alerts = alerts.filter((alert)=> alert_filter(alert, associated as Associated))
  }

  return alerts;
}


function deliverySystemAlert(alert:SystemAlert, associated:Associated):boolean{
  if(alert.type !== "DELIVERY") return true;

  try{
    let deliveryDate = DeliveryDaysInteractor.getNextDeliveryDate(associated);
    return isTomorrow(deliveryDate);
  }catch(e){return false}

}

function birthdaySystemAlert(alert:SystemAlert, associated:Associated):boolean{
  if(alert.type !== "BIRTHDAY") return true;

  let birthday = associated.birthdate;
  let today = new Date()
  today.setFullYear(0);
  birthday.setFullYear(0);
  
  return isSameDay(birthday, today);
}

function latePaymentSystemAlert(alert:SystemAlert, associated:Associated):boolean{
  if(alert.type !== "LATEPAYMENT") return true;
  return isInLatePaymentState(associated);
}