import React, { useEffect, useState } from "react";
import {
  Container,
  Button,
  makeStyles,
  TextField,
  InputAdornment,
} from "@material-ui/core";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";


import FormField from "../../../app/components/atoms/FormField";
import ConfigWrapper from "../../../app/components/atoms/ConfigWrapper";
import EditAppConfigsPresenter from "../../../app/presenters/admin/configs/EditAppConfigsPresenter";
import usePresenter from "../../../app/redux/usePresenter";
import PriceMaskedInput from "../../../components/inputMasks/PriceMaskedInput";
import LoadingCircle from "../../../app/components/atoms/LoadingCircle";

const schema = yup.object().shape({
  entry_tax: yup.number()
});

const useStyles = makeStyles((theme) => ({

}));

function AdminConfigs() {
  const [loading, setLoading] = useState(false);

  const presenter_data = usePresenter(new EditAppConfigsPresenter());
  const { register, handleSubmit, errors, control, reset, watch , getValues} = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      entry_tax: "0.0"
    }
  });

  useEffect( () => {
    if(!presenter_data) return;
    reset(presenter_data)
  }, [reset, presenter_data])

  const submitHandler = async (formInfo) => {
    setLoading(true);
    await EditAppConfigsPresenter.updateAppConfig(formInfo);
    setLoading(false);
  }

  if(!presenter_data) return (<></>);
  if(loading) return (<LoadingCircle />);
  return (
    <Container maxWidth="xs">
       <form onSubmit={handleSubmit(submitHandler)}>
        <div style={{height: "30px"}}></div>
        <ConfigWrapper
          name={"entry_tax_abled"}
          expected_to_show={"true"} 
        >
          <PriceField 
            control={control}
            register={register}
            errors={errors}
            getValues={getValues}
            label={"Taxa de entrada"}
            field_name={"entry_tax"}
          />
        </ConfigWrapper>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
        >
          Confirmar mudanças
        </Button>
       </form>
    </Container>
  );
}

export default AdminConfigs;

const PriceField = ({register,errors, label, field_name, control})=> {
  return (
    <FormField
      control={control}
      label={label}
      field_name={field_name}
      errors={errors}
      type="number"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">R$</InputAdornment>
        ),
        inputComponent: PriceMaskedInput,
        inputRef: register,
        defaultValue: control.defaultValuesRef.current.entry_tax.toString()
      }}
    />
  )
}