import ApplicationEntity from "./ApplicationEntity";
import database from "../database/DataBaseConfig";

// Helpers Types
export type IndividualPurchase = {
  productId: string;
  quantity: number;
  required?: boolean;
};

export type PaymentPlanItem = IndividualPurchase & {
  frequency: "weekly" | "biweekly";
  productId: string;
};

export type DeliveryInfoProp = {
  driverId: string;
  weekDay: number;
  order: string;
  lastDeliveryDate: Date | undefined;
  baseDeliveryDate: Date | undefined;
  pendingItems: IndividualPurchase[] | undefined;
};

type intolarance = {
  flavorId: string;
};

/****  Define Subcollection Data *****/
export type DeliveryFields = {
  date: Date;
  observation: string;
  products: IndividualPurchase[];
  returnedProducts: IndividualPurchase[];
};

export default class Associated extends ApplicationEntity {
  paymentPlan: PaymentPlanItem[];
  individualPurchases: IndividualPurchase[];
  deliveryInfo: DeliveryInfoProp;
  address: string;
  deliveryAddress: string;
  intolerances: intolarance[];
  neighborhoodId: string;
  displayName: string;
  suspendedDates: Date[] | undefined;
  acceptedTerm: boolean;
  deleted: boolean | undefined;
  cep: string;

  cpf: string;
  phone: string;
  email: string;
  birthdate: Date;
  entry_date?: Date;
  observation: string;

  delivery_observation_on_date?: {
    [date: string]: string;
  };

  delivery_observation: string | undefined;

  credits?: string;

  constructor(id: string, data: any) {
    super(id, data);
    Object.assign(this, data);
  }

  async getNeighborhood() {
    return database.neighborhood_db.find(this.neighborhoodId);
  }
}
