import Associated from "../../entities/Associated";
import AssociatedDBInterface from "../interfaces/AssociatedDBInterface";
import { db } from "../../../services/Firebase";
import { deleteStorage, injectReduxStore } from "../../redux/backendStorage";
import { findWithRedux } from "./utils/find_firebase";
import {
  compareDeliveryDates,
  normalizeDeliveryDate,
} from "../../../utils/date_manipulation";
import { isBefore } from "date-fns";
import firebase from "../../../services/Firebase";
import Receipt from "../../entities/Receipt";

const COLLECTION_NAME = "associated";
export default class AssociatedFirebase implements AssociatedDBInterface {
  async getWeekDayAssociatedsForDriver(
    day_number: number,
    driver_id: string
  ): Promise<Associated[]> {
    let query = db
      .collection(COLLECTION_NAME)
      .where("deliveryInfo.weekDay", "==", day_number)
      .where("deliveryInfo.driverId", "==", driver_id);
    let response = await query.get();

    let ret_list: Associated[] = [];
    response.docs.forEach((obj) => {
      ret_list.push(this.createAssociatedFromProps(obj.ref.id, obj.data()));
    });

    return ret_list;
  }

  // TODO: dar um jeito de otimizar essa funcao
  async allActiveAssociateds(): Promise<Associated[]> {
    return injectReduxStore(
      () => this.queryOnFirebaseActiveAssociateds(),
      "/active_associateds"
    );
  }

  async find(id: string) {
    return findWithRedux(
      id,
      COLLECTION_NAME,
      undefined,
      this.createAssociatedFromProps
    );
  }

  async activateAssociated(id: string) {
    await db.collection(COLLECTION_NAME).doc(id).update({ deleted: false });
  }

  async inactivateAssociated(id: string) {
    await db.collection(COLLECTION_NAME).doc(id).update({ deleted: true });
  }

  async addSuspendedDay(
    associated_id: string,
    new_date: Date
  ): Promise<boolean> {
    let associated = (await this.find(associated_id)) as Associated;
    if (!associated) return false;

    let suspendedDays = associated.suspendedDates || [];
    suspendedDays.push(new_date);
    let filter_dates = suspendedDays.map((date) => normalizeDeliveryDate(date));
    filter_dates = filter_dates.filter(
      (date) => !isBefore(date, normalizeDeliveryDate(new Date()))
    );

    await db.collection(COLLECTION_NAME).doc(associated_id).update({
      suspendedDates: filter_dates,
    });

    deleteStorage(COLLECTION_NAME + "/" + associated_id);
    return true;
  }

  async removeSuspendedDay(
    associated_id: string,
    removed_date: Date
  ): Promise<boolean> {
    let associated = (await this.find(associated_id)) as Associated;
    if (!associated) return false;

    let suspendedDays = associated.suspendedDates || [];

    let filter_dates = suspendedDays.map((date) => normalizeDeliveryDate(date));
    filter_dates = filter_dates.filter(
      (date) => !compareDeliveryDates(date, removed_date)
    );
    filter_dates = filter_dates.filter(
      (date) => !isBefore(date, normalizeDeliveryDate(new Date()))
    );

    await db.collection(COLLECTION_NAME).doc(associated_id).update({
      suspendedDates: filter_dates,
    });

    deleteStorage(COLLECTION_NAME + "/" + associated_id);
    return true;
  }

  async update(id: string, newInfo: Associated): Promise<null> {
    // TODO: codar aq
    // const createAssociated = firebase.functions().httpsCallable("createAssociated");
    const updateAssociated = firebase
      .functions()
      .httpsCallable("updateAssociated");
    let data: any = newInfo;
    delete data.deliveryInfo;
    if (!data.password) delete data.password;
    await updateAssociated({
      uid: id,
      data: data,
    });

    deleteStorage(COLLECTION_NAME + "/" + id);
    return null;
  }

  async resetDriverInfo(id: string) {
    await db
      .collection(COLLECTION_NAME)
      .doc(id)
      .set(
        {
          deliveryInfo: {
            driverId: null,
            weekDay: null,
          },
        },
        { merge: true }
      );
    deleteStorage(COLLECTION_NAME + "/" + id);
  }

  async updateCredits(id: string, receipt: Receipt) {
    const createReceipt = firebase.functions().httpsCallable("createReceipt");
    let data: any = Object.assign({}, receipt);
    data.date = data.date
      ? data.date.toDateString()
      : new Date().toDateString();
    data.paidValue = String(data.paidValue);

    let response: any = await createReceipt(receipt);
    if (!response.data["error"]) {
      deleteStorage(COLLECTION_NAME + "/" + id);
    }
  }

  private async queryOnFirebaseActiveAssociateds() {
    let query = db.collection(COLLECTION_NAME);

    let response = await query.get();

    let ret_list: Associated[] = [];
    response.docs.forEach((obj) => {
      ret_list.push(this.createAssociatedFromProps(obj.ref.id, obj.data()));
    });

    return ret_list.filter(
      (associated) =>
        associated.deleted === false ||
        associated.deleted === undefined ||
        associated.deleted === null
    );
  }

  private createAssociatedFromProps(id: string, data: any) {
    let associated = new Associated(id, data);
    if (data.deliveryInfo.lastDeliveryDate) {
      associated.deliveryInfo.lastDeliveryDate =
        data.deliveryInfo.lastDeliveryDate.toDate();
    }
    if (data.deliveryInfo.baseDeliveryDate) {
      associated.deliveryInfo.baseDeliveryDate =
        data.deliveryInfo.baseDeliveryDate.toDate();
    }
    if (data.birthdate) {
      associated.birthdate = data.birthdate.toDate();
    }
    if (data.entry_date) {
      try {
        associated.entry_date = data.entry_date.toDate();
      } catch (error) {
        associated.entry_date = new Date(data.entry_date);
      }
    }
    if (data.suspendedDates) {
      associated.suspendedDates = data.suspendedDates.map((date: any) =>
        date.toDate()
      );
    }
    associated.intolerances = associated.intolerances || [];
    if (!associated.credits) associated.credits = "0";

    return associated;
  }
}
