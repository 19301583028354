import {Avatar, Divider, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText} from "@material-ui/core";
import React from "react";
import {DailyShippingProductData} from "../../../presenters/driver/DailyShippingPresenter";


export type DailyShippingListItemType = {
    index: number
} & DailyShippingProductData


export default function DailyShippingListItem(props : DailyShippingListItemType){

    return (<div key={props.index}>
        <ListItem>
            <ListItemAvatar>
                <Avatar
                    src={props.image_url}
                    alt={props.name}
                />
            </ListItemAvatar>
            <ListItemText primary={props.name} secondary={(
                <>
                    <>{props.total - props.intolerant_count} - {props.flavor_name}</><br />
                    {props.intolerant_count !== 0 ? (<>{props.intolerant_count} - Sabor Alternativo</>) : undefined }
                </>)} />
            <ListItemSecondaryAction>{props.total}un</ListItemSecondaryAction>
        </ListItem>
        <Divider variant="inset" component="li" />
    </div>)

}