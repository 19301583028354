import React, { useEffect, useState } from 'react';
import { useHistory }    from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector }  from 'react-redux';
import {
  Button,
  CircularProgress,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
}  from '@material-ui/core';
import { useFirestore } from 'react-redux-firebase';
import Products from '../../services/db/Products';
import { calculatePlanPriceWithId } from '../../app/interactors/ReceiptsInteractor';

const useStyles = makeStyles((theme) => ({
  hText:{
    margin: theme.spacing(2),
  },
  rodapeComentary:{fontSize: "12px", color: "rgb(111 111 111)", margin:"10px auto"},
}));

// import { Container } from './styles';

function printFloat(f){
  return parseFloat(f).toFixed(2).replace(".", ",")
}

const traduzFrequncia = (freq)=>{
  if(freq === "biweekly") return "Quinzenal"
  else return "Semanal"
}

const months = ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"];
const week =  ["Domingos", "Segundas", "Terças", "Quartas", "Quintas", "Sextas", "Sabados"];

function MyPlan() {
  const db        = useFirestore();
  const classes     = useStyles();
  const history     = useHistory();

  const [plan, setPlan] = useState({})
  const [totalMensal, setTotalMensal] = useState(0)
  const [frete, setFrete] = useState(0)
  const [loading, setLoading]   = useState(true)

  const associated    = useSelector((state) => state.backend.associated);

  const monthEvaluated = new Date().getMonth()
  useEffect(()=>{
    const fetchData = async ()=>{
      let planItems = await associated.planHelper.getAllPlanItems()
      let total = await associated.planHelper.planPriceOnMonthWithSuspendedDays(monthEvaluated)
      total = await calculatePlanPriceWithId(associated.docRef.id);
      
      setFrete(Number(associated.neighborhood.props.taxPlan))
      setTotalMensal(total);
      setPlan(planItems)
      setLoading(false);
    }
    fetchData();
  },[db, associated])

  const editarPlanoClick = () =>{
    history.push("/associated/plan/index");
  }
  return <div>
    {loading ? (<div className="loading-container"><CircularProgress /></div>) : (
    <>
      <Divider/>
      <div className={classes.hText}>
        <h3>{"Produtos"}</h3>
      </div>
      <Divider variant="inset"/>
      <List>
      {plan.map((item, index)=>(
        <div key={index}>
        <ListItem>
          <ListItemAvatar>
            <div>
              {item.quantity}{" uni"}
            </div>
          </ListItemAvatar>
          <ListItemText
            primary={item.props.name + "("+ traduzFrequncia(item.frequency) +")"}
          />
          <ListItemSecondaryAction>
            {"R$"}{printFloat(item.props.price)}
          </ListItemSecondaryAction>
        </ListItem>
        </div>
      ))}
      </List>
      <Divider variant="inset"/>
      <List>
      <ListItem>
          <ListItemAvatar>
            <b>
              {"Frete"}
            </b>
          </ListItemAvatar>
          <ListItemSecondaryAction>
            {"R$"}{printFloat(frete)}
          </ListItemSecondaryAction>
      </ListItem>
      </List>
      <Divider variant="inset"/>

      <div className={classes.hText}>
        <h3>{"Mensalidade: "} {"R$"}{printFloat(totalMensal)}</h3>
        <div className={classes.rodapeComentary}>
          Cálculo feito para o mês de {months[monthEvaluated]}, com entregas nas {week[associated.getDeliveryDay()]}
        </div>
      </div>
      <div style={{display:"flex", margin:"auto ", justifyContent:"right"}} className={classes.hText}>
        <Button variant="contained" style={{fontSize:12, position:"absolute", right: "12px"}} color="primary" onClick={editarPlanoClick}>
          Editar plano
        </Button>
      </div>
    </>)}
  </div>;
}

export default MyPlan;
