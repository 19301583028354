import Associated_Delivery from "../../entities/Associated_Delivery";
import Associated_DeliveryDBInterface from "../interfaces/Associated_DeliveryDBInterface";
import {db} from "../../../services/Firebase"
import { normalizeDeliveryDate } from "../../../utils/date_manipulation";
import firebase from "../../../services/Firebase"
import { deleteStorage, injectReduxStore } from "../../redux/backendStorage";

export default class Associated_DeliveryFirebase implements Associated_DeliveryDBInterface{

  async handleDelivery(delivery_data: Associated_Delivery):Promise<boolean>{
    const handleDeliveryFirebase = firebase.functions().httpsCallable('handleDelivery');
    try{
      await handleDeliveryFirebase(delivery_data);
      let normalized_date = normalizeDeliveryDate(new Date());
      deleteStorage("associated/"+delivery_data.associatedId);
      deleteStorage("associated/"+delivery_data.associatedId+"/delivery/"+normalized_date.toDateString())
      return true;
    }catch(err){
      return false;
    }
  }

  async deliveriesOnTimeRange(associated_id: string, initial_date:Date, end_date:Date):Promise<Date[]>{
    let q = db.collection("associated/"+associated_id+"/delivery")
      .where("date", "<", end_date)
      .where("date", ">", initial_date)
    
    let response = await q.get();

    let ret_list:Date[] = []

    response.docs.forEach(obj => {
      ret_list.push(obj.data().date.toDate())
    });
    return ret_list
  }
  
  async getDeliveriesOnTimeRange(associated_id: string, initial_date:Date, end_date:Date):Promise<Associated_Delivery[]>{
    let q = db.collection("associated/"+associated_id+"/delivery")
      .where("date", "<", end_date)
      .where("date", ">", initial_date)
    
    let response = await q.get();

    let ret_list:Associated_Delivery[] = []

    response.docs.forEach(obj => {
      ret_list.push(this.createDeliveryFromProps(obj.ref.id, obj.data()))
    });

    return ret_list
  }

  async findDeliveriesOnDate(associated_id:string, date:Date):Promise<Associated_Delivery[]>{
    let normalized_date = normalizeDeliveryDate(date);
    return injectReduxStore(()=>{
     return this.searchDeliveries(associated_id, normalized_date) 
    }, "associated/"+associated_id+"/delivery/"+normalized_date.toDateString())
  }

  private async searchDeliveries(associated_id:string, date:Date):Promise<Associated_Delivery[]>{
    let normalized_date = normalizeDeliveryDate(date);
    let collectionPath = "associated/"+associated_id+"/delivery"
    let query = db.collection(collectionPath)
      .where("date", "==", normalized_date);

    let response = await query.get();

    let ret_list:Associated_Delivery[] = []
    response.docs.forEach(obj => {
      ret_list.push(this.createDeliveryFromProps(obj.ref.id, obj.data()))
    });

    return ret_list
  }

  private createDeliveryFromProps(id:string, data:any):Associated_Delivery{
    let instance = new Associated_Delivery(id, data);
    instance.date = data.date.toDate();
    return instance;
  }
}
