// Mudar tambem arquivo "firebase-messaging-sw"

const firebaseConfig = {
  apiKey: "AIzaSyAQu3r8-GPlW10OvIaPMkh-7Q16IKh67j4",
  authDomain: "milramas-192b1.firebaseapp.com",
  projectId: "milramas-192b1",
  storageBucket: "milramas-192b1.appspot.com",
  messagingSenderId: "306779445994",
  appId: "1:306779445994:web:7f3f4638dfd23da87f1593",
  databaseURL: "https://milramas-192b1.firebaseio.com"
};

export default firebaseConfig