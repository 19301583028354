import { ExtendedFirestoreInstance } from "react-redux-firebase";
import Associated from "../../Associated";
import Driver from "../../Driver";
import Flavor from "../../Flavor";
import Products from "../../Products";

import {deliveryInfo} from "../associated/DeliveryHelper"

export default class DriverDeliveries{
  constructor(public db:ExtendedFirestoreInstance, public driver:Driver){}

  async getAllProductsFor(weekDay: number){
    let allAssociateds = await Associated.search(this.db, "deliveryInfo.driverId", this.driver.docRef.id)
    let weekAssociateds = Object.values(allAssociateds).filter((associated) => associated.props.deliveryInfo.weekDay === weekDay);
    let infosReduced: deliveryInfo = {
			deliveryType: "nextDelivery",
			totalProducts: 0,
			products: {}
		};
    const flavors = await Flavor.all(this.db);
    for(let associated of weekAssociateds){
      let associatedInfo = await associated.delivery.getDeliveryInfo(new Date());
      infosReduced = this.combineDeliveryInfos(associatedInfo, infosReduced);
    }

    for(let productId of Object.keys(infosReduced.products)){
      // TOOD: Ajeitar logica de flavor
      let prod = infosReduced.products[productId]
      if(prod.props && prod.props.flavorId)
        infosReduced.products[productId].flavorName = flavors[prod.props.flavorId].props.name;
      else 
        infosReduced.products[productId].flavorName = "Sem sabor"
    }
    return infosReduced
  }

 async getPendingItemsForWeekDay(weekDay : number){


     let allAssociateds = await Associated.search(this.db, "deliveryInfo.driverId", this.driver.docRef.id)
     let rawPendingItemsList : any[] = []
     let prettyPendingItemsList : any[] = []
     await Promise.all(Object.values(allAssociateds).map(async (associated)=>{
         if (Number(associated.props.deliveryInfo.weekDay) === weekDay)
            return rawPendingItemsList.push(await associated.getPendingItems())
     }))

     console.log(rawPendingItemsList)

     rawPendingItemsList.forEach((associated)=>{
         associated.forEach((item :any)=>{
             let foundItem = prettyPendingItemsList.find((pendingItem)=>{
                 if(!item.returnableName)
                     return false
                 if(!pendingItem.returnableName)
                     return false
                 return item.returnableName.trim() === pendingItem.returnableName.trim()}
             )

             if(foundItem === undefined){
                 if (item.returnableName)
                    prettyPendingItemsList.push(item)
             }else{
                 prettyPendingItemsList[prettyPendingItemsList.indexOf(foundItem)].quantity += Number(item.quantity)
             }
         })
     })

     return prettyPendingItemsList
 }



  async getOrderFor(weekDay: number):Promise<Associated[]>{
    let associatedsIdsOrder = this.getOrderFromDay(weekDay);
    return this.createAssociatedListFromOrder(associatedsIdsOrder);
  }

  updateAssociatedPosition(weekDay:number, associatedId:string, position:number){
    let newList = [...this.getOrderFromDay(weekDay)]
    newList.splice(newList.indexOf(associatedId), 1); // remove da lista
    newList.splice(position, 0, associatedId);        // adiciona-o em outra posicao
    return newList
  }

  addAssociatedOnPosition(weekDay:number, associatedId:string, position:number){
    let newList = [...this.getOrderFromDay(weekDay)]
    newList.splice(position, 0, associatedId);
    return newList
  }

  removeAssociatedFromDriver(weekDay:number, associatedId:string){
    let newList = [...this.getOrderFromDay(weekDay)]
    newList.splice(newList.indexOf(associatedId), 1);
    return newList
  }

  private getOrderFromDay(weekDay:number){
    let associatedsIdsOrder:string[] = []
    if(this.driver.props.deliveries){
      Object.values(this.driver.props.deliveries).forEach( driverDeliveryInfo => {
        if(driverDeliveryInfo.weekDay === weekDay){
          associatedsIdsOrder = driverDeliveryInfo.order;
        }
      })
    }
    return associatedsIdsOrder;
  }

  private async createAssociatedListFromOrder(orderList:string[]){
    let allAssociatedsFromDriver = await Associated.search(this.db, "deliveryInfo.driverId", this.driver.docRef.id)
    let associatedsOrdered:Associated[] = []


    for(let associatedId of orderList){
      if(allAssociatedsFromDriver[associatedId] === undefined){
        continue;
      }
      let associated = allAssociatedsFromDriver[associatedId]
      associatedsOrdered.push(associated)
    }

    return associatedsOrdered
  }

  private combineDeliveryInfos(src:deliveryInfo, dest:deliveryInfo){
    for(let productId of Object.keys(src.products)){
      let productData = src.products[productId];
      dest.totalProducts += productData.total;
      if(dest.products[productId]){
        dest.products[productId].total += productData.total;
      }
      else{
        dest.products[productId] = {
          ... productData,
          total: productData.total,
          intolerants: 0,
          intolerant: false,
        }
      }
    }
    return dest
  }
}
