import { Avatar, Divider, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText } from "@material-ui/core";
import React from "react";

export default function ProductInfoListItem({product, showAvatar}) {
  
  return (
    <div key={product.id}>
      <ListItem>
        {showAvatar && (
          <ListItemAvatar>
            <Avatar
              src={product.image_url}
              alt={product.name}
            />
          </ListItemAvatar>
        )}
        <ListItemText primary={product.name} secondary={(
        <>
          <>{product.total - product.intolerant_count} - {product.flavor_name}</><br />
          {product.intolerant_count !== 0 ? (<>{product.intolerant_count} - Sabor Alternativo</>) : undefined }
        </>)} />
        <ListItemSecondaryAction>{product.total}un</ListItemSecondaryAction>
      </ListItem>
      <Divider variant="inset" component="li" />
    </div>
  )
}