import ApplicationEntity from "./ApplicationEntity";

export class Bill extends ApplicationEntity {
  date: Date | string;
  name: string;
  observation: string;
  price: {
    individual: number;
    quantity: number;
    total: number;
  };

  type:
    | "discount"
    | "normal"
    | "avulso"
    | "broken"
    | "suspended_days"
    | "individual_future_delivery";

  constructor(id: string, data: any) {
    super(id, data);
    Object.assign(this, data);
  }
}
export default class Receipt extends ApplicationEntity {
  file: string | undefined;
  paidValue: string;
  associatedId: string;
  associatedName: string;
  date: Date;
  status: "accepted" | "refused" | "pending";
  type: undefined | "CORRECAO" | "associated_creation";

  bills: Bill[];
  credits_before_confirmation: string;
  credits_after_confirmation: string;
  base_value: string;

  constructor(id: string, data: any) {
    super(id, data);
    this.bills = [];
    this.credits_before_confirmation = "0";
    this.credits_after_confirmation = "0";
    this.base_value = "0";
    Object.assign(this, data);
  }
}
