import React from "react";
import "../../styles/DailyShipping.css";

import {
  Container,
  Toolbar,
  Typography,
  Divider,
  Avatar,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  ListItemSecondaryAction,
  CircularProgress,
} from "@material-ui/core";

import { useSelector } from "react-redux";
import usePresenter from "../../app/redux/usePresenter";
import DailyShippingPresenter from "../../app/presenters/driver/DailyShippingPresenter";
import DailyShippingList from "../../app/components/molecules/driver/DailyShippingList";
import DailyShippingInformation from "../../app/components/atoms/driver/DailyShippingInformation";

export default function DailyShipping() {
  const driverAuth = useSelector((state) => state.firebase.auth);
  const data = usePresenter(new DailyShippingPresenter(driverAuth.uid));

  if (data === undefined) {
    return (
      <div className="loading-container">
        <CircularProgress />
      </div>
    );
  }

  return (
    <div>
      <Container>
        <DailyShippingInformation
          label={"Número de entregas do dia:"}
          information={data.total_deliveries.toString()}
        />
        <Divider variant="inset" />
        <DailyShippingInformation
          label={"Total de produtos:"}
          information={data.total_products.toString()}
        />
        <Divider variant="inset" />
        <DailyShippingInformation
          label={"Total de produtos a receber:"}
          information={data.total_products_to_return.toString()}
        />
      </Container>
      <Divider />
      <DailyShippingList products={data.products} />
    </div>
  );
}
