import { ExtendedFirestoreInstance } from "react-redux-firebase";
import Associated, { IndividualPurchase } from "../../Associated";
import Products from "../../Products";

export default class IndividualPurchasesHelper{
	constructor(public db:ExtendedFirestoreInstance, public associated:Associated){}

	async getAll(){
		if(!this.associated.props.individualPurchases) return [];
		let products = await Products.all(this.db);	
		return this.associated.props.individualPurchases.map( (indiv) =>{
			return {
				product: products[indiv.productId],
				productId: indiv.productId,
				quantity: indiv.quantity,
				total: indiv.quantity * products[indiv.productId].props.price
			}
		})
	}

	async removePurchase(productRef:string){
		if(!this.associated.props.individualPurchases) return;
		let updatedList = this.associated.props.individualPurchases.filter( (val) => {
			return val.productId !== productRef;
		})
		console.log(updatedList);
		this.update(updatedList);
	}

	async updatePurchase(productRef:string, quantity:number){
		let updatedList = this.associated.props.individualPurchases || [];
		
		// Check if product is already purchased and update it
		let alreadyInList = false
		updatedList.forEach( (p, index, list) =>{
			if(p.productId === productRef){
				list[index].quantity 	= quantity;
				alreadyInList	 		= true;
			}
		})

		// If not purchases, add to list
		if( ! alreadyInList ){
			updatedList.push({
				productId: productRef,
				quantity: quantity
			})
		}
		return await this.update(updatedList);
	}

	public alreadyPurchased(productRef:string):IndividualPurchase | undefined{
		if(!this.associated.props.individualPurchases) return;
		for(let p of this.associated.props.individualPurchases){
			if(p.productId === productRef){
				return p;
			}
		}
	}



	private async update(updatedList:IndividualPurchase[]){
		await Associated.update(this.db, this.associated.docRef.id, {
			individualPurchases: updatedList
		})
		this.associated.props.individualPurchases = updatedList
	}
}