import React from "react";
import {List } from "@material-ui/core";
import ProductInfoListItem from "./ProductInfoListItem";

export default function ProductInfoList({product_list, showAvatar}) {
  
  return (
    <List>
      {product_list.map((product) => {
        return (
          <ProductInfoListItem product={product} showAvatar={showAvatar} />
        );
      })}
    </List>
  )
}