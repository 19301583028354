import React from "react";
import {
  Container,
  makeStyles,
} from "@material-ui/core";

import { ReactComponent as IdolatreLogo } from "../../../config/assets/login-logo.svg";
import loginBackground from "../../../config/assets/login-background.jpg";

const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: "100%",
  },
  avatar: {
    margin: theme.spacing(2),
  },
}));

type LoginTemplateProps = {

}

const LoginTemplate: React.FC = (props: React.PropsWithChildren<LoginTemplateProps>) => {
  const classes = useStyles();

  return (
    <Container
      component="main"
      maxWidth="xs"
      style={{
        backgroundImage: `url(${loginBackground})`,
        backgroundSize: "cover",
        height: "100vh",
      }}
    >
      <div className={classes.paper}>
        <IdolatreLogo className={classes.avatar} />
        {props.children}
      </div>
    </Container >
  );
}

export default LoginTemplate;