export default {
  theme:{
    mainColor: "#B01F8C",
    driver: {
      topBarColor:"#FAA21B",
      themeColor:"#FAA21B"
    },
    admin: {
      topBarColor:"#B01F8C",
      themeColor:"#B01F8C"
    },
    associated:{
      topBarColor:"#40C0C0",
      themeColor:"#40C0C0"
    }
  }
}