import { getMonth } from "date-fns";
import { ReceiptBillItemProps } from "../../components/atoms/shared/receipt/ReceiptBillItem";
import { ShowReceiptInfoProps } from "../../components/organisms/receipt/ShowReceiptInfo";
import Receipt, { Bill } from "../../entities/Receipt";

const MONTHS = [
  "Janeiro", "Fevereiro", "Março", 
  "Abril", "Maio", "Junho", 
  "Julho", "Agosto", "Setembro", 
  "Outubro", "Novembro", "Dezembro"
];


export function getInfoForShowReceipt(receipt: Receipt):ShowReceiptInfoProps{
  let bills = []
  bills.push({
    list_name: "Avulsos",
    bills: getBillsItemProps(receipt.bills, "avulso")
  })
  bills.push({
    list_name: "Adicionais",
    bills: getBillsItemProps(receipt.bills, "broken")
  })
  bills.push({
    list_name: "Descontos",
    bills: getBillsItemProps(receipt.bills, "discount")
  })
  bills.push({
    list_name: "Produtos Individuais ainda não entregues",
    bills: getBillsItemProps(receipt.bills, "individual_future_delivery")
  })

  bills.push({
    list_name: "Dias suspensos",
    bills: getBillsItemProps(receipt.bills, "suspended_days")
  })

  return {
    credits: receipt.credits_before_confirmation,
    month_name: MONTHS[getMonth(receipt.date) ],
    base_receipt_value: receipt.base_value,
    bills
  }
}


function getBillsItemProps(bills: Bill[], type: string):ReceiptBillItemProps[]{
  return bills.filter( (bill)=> bill.type === type ).map(createBillItemProps)
}

function createBillItemProps(bill: Bill):ReceiptBillItemProps{
  return {
    value: String(bill.price.total),
    description: bill.name
  }
}
