import database from "../../database/DataBaseConfig";
import Associated from "../../entities/Associated";
import Product from "../../entities/Product";
import AssociatedInteractor from "../AssociatedInteractor";
import DeliveryDaysInteractor from "./DeliveryDaysInteractor";
import DeliveryTypeInteractor, {
  DeliveryTypes,
} from "./DeliveryTypeInteractor";

export type DeliveryInfo = {
  totalProducts: number;
  deliveryType: DeliveryTypes;
  products: {
    [id: string]: ProductOnDelivery;
  };
};

export type ProductOnDelivery = {
  flavorName: string;
  intolerants: number;
  total: number;
  intolerant: boolean;
} & Product;

type ProductInfo = {
  quantity: number;
  productId: string;
};

export default class DeliveryInfoInteractor {
  static async combineDeliveryInfos(
    src: DeliveryInfo,
    srcAssociated: Associated,
    dest: DeliveryInfo
  ) {
    for (let product of Object.values(src.products)) {
      let product_info = {
        productId: product.id,
        quantity: product.total,
      };
      dest = await DeliveryInfoInteractor.addProductToInfo(
        srcAssociated,
        product_info,
        dest
      );
    }
    return dest;
  }

  static async deliveryInfoForAssociateds(
    associateds: Associated[],
    date: Date
  ) {
    let combined_delivery_info: DeliveryInfo = {
      products: {},
      totalProducts: 0,
      deliveryType: "combinedInfo",
    };
    for (let associated of associateds) {
      let delivery_info = await DeliveryInfoInteractor.deliveryInfoOnDate(
        date,
        associated
      );
      combined_delivery_info =
        await DeliveryInfoInteractor.combineDeliveryInfos(
          delivery_info,
          associated,
          combined_delivery_info
        );
    }
    return combined_delivery_info;
  }

  static async deliveryInfoOnDate(date: Date, associated: Associated) {
    let delivery_info: DeliveryInfo = {
      products: {},
      totalProducts: 0,
      deliveryType: "notReceive",
    };

    let type = DeliveryTypeInteractor.deliveryTypeOnDate(date, associated);

    if (type === "nextDelivery") {
      delivery_info = await DeliveryInfoInteractor.addItemsFromPlan(
        associated,
        date,
        delivery_info
      );
      delivery_info =
        await DeliveryInfoInteractor.addItemsFromIndividualPurchases(
          associated,
          delivery_info
        );
    } else if (type === "futureDelivery") {
      delivery_info = await DeliveryInfoInteractor.addItemsFromPlan(
        associated,
        date,
        delivery_info
      );
    } else if (type === "alreadyReceived") {
      delivery_info = await DeliveryInfoInteractor.getDeliveryInfoFromHistory(
        associated,
        date,
        delivery_info
      );
    }

    delivery_info.deliveryType = type;
    return delivery_info;
  }

  static async getFullDeliveryInfo(date: Date, associated: Associated) {
    let delivery_info: DeliveryInfo = {
      products: {},
      totalProducts: 0,
      deliveryType: "nextDelivery",
    };
    delivery_info = await DeliveryInfoInteractor.addItemsFromPlan(
      associated,
      date,
      delivery_info
    );
    delivery_info =
      await DeliveryInfoInteractor.addItemsFromIndividualPurchases(
        associated,
        delivery_info
      );
    return delivery_info;
  }

  static async addItemsFromPlan(
    associated: Associated,
    date: Date,
    delivery_info: DeliveryInfo
  ): Promise<DeliveryInfo> {
    for (let plan_product of associated.paymentPlan) {
      if (
        plan_product.frequency === "weekly" ||
        (plan_product.frequency === "biweekly" &&
          DeliveryDaysInteractor.shouldReceiveBiweeklyProductOnWeek(
            date,
            associated
          ))
      ) {
        delivery_info = await DeliveryInfoInteractor.addProductToInfo(
          associated,
          plan_product,
          delivery_info
        );
      }
    }
    return delivery_info;
  }

  static async addItemsFromIndividualPurchases(
    associated: Associated,
    delivery_info: DeliveryInfo
  ) {
    if (associated.individualPurchases) {
      for (let product_info of associated.individualPurchases) {
        delivery_info = await DeliveryInfoInteractor.addProductToInfo(
          associated,
          product_info,
          delivery_info
        );
      }
    }
    return delivery_info;
  }

  static async addProductToInfo(
    associated: Associated,
    info: ProductInfo,
    delivery_info: DeliveryInfo
  ) {
    let product = await database.product_db.find(info.productId);
    if (!product) return delivery_info;

    delivery_info.totalProducts += Number(info.quantity);
    if (delivery_info.products[info.productId]) {
      delivery_info.products[info.productId].total += Number(info.quantity);
    } else {
      delivery_info.products[info.productId] = {
        total: Number(info.quantity),
        flavorName: await AssociatedInteractor.getFlavorNameForProduct(
          associated,
          product
        ),
        intolerants: 0,
        intolerant: false,
        ...product,
      };
    }

    // Add intolerant information
    if (
      AssociatedInteractor.associatedIsIntolerantTo(
        associated,
        product.flavorId
      )
    ) {
      delivery_info.products[info.productId].intolerants += Number(
        info.quantity
      );
      delivery_info.products[info.productId].intolerant = true;
    }

    return delivery_info;
  }

  static async getDeliveryInfoFromHistory(
    associated: Associated,
    date: Date,
    delivery_info: DeliveryInfo
  ) {
    let deliveries_history =
      await database.associated_delivery_db.findDeliveriesOnDate(
        associated.id,
        date
      );
    for (let delivery of deliveries_history) {
      for (let product of delivery.products) {
        delivery_info = await DeliveryInfoInteractor.addProductToInfo(
          associated,
          product,
          delivery_info
        );
      }
    }
    return delivery_info;
  }

  static async planDeliveryInfo(associated: Associated, date: Date) {
    let delivery_info: DeliveryInfo = {
      products: {},
      totalProducts: 0,
      deliveryType: "futureDelivery",
    };
    delivery_info = await DeliveryInfoInteractor.addItemsFromPlan(
      associated,
      date,
      delivery_info
    );
    return delivery_info;
  }
}
