import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

import EditAssociatedForm from "../../../app/components/templates/admin/associated/EditAssociatedForm";

import usePresenter from "../../../app/redux/usePresenter";
import EditAssociatedPresenter from "../../../app/presenters/admin/associated/EditAssociatedPresenter";
import { Button, Container } from "@material-ui/core";

import { useDispatch } from "react-redux";
import { useHistory } from "react-router";

import DriverOrderInteractor from "../../../app/interactors/driver/DriverOrderInteractor";
import { db } from "../../../services/Firebase";
import { deleteStorage } from "../../../app/redux/backendStorage";
import LoadingCircle from "../../../app/components/atoms/LoadingCircle";

function EditAssociated() {
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = location.state ? location.state : {};

  const presenter_data = usePresenter(new EditAssociatedPresenter(id));

  useEffect(() => {
    dispatch({
      type: "app/menuOptions/update",
      payload: [
        {
          name: "Ver pagamentos",
          redirectPath: "/admin/associated_receipt",
          state: { id: id },
        },
        {
          name: "Ver Entregas",
          redirectPath: "/admin/associated/show_deliveries",
          state: { id: id },
        },
        {
          name: "Ver chat",
          redirectPath: "/admin/chat",
          state: { id: id },
        },
      ],
    });
  }, [id, dispatch]);

  if (!presenter_data) {
    return <LoadingCircle show />;
  }

  return (
    <div>
      <EditAssociatedForm {...presenter_data} />

      <div style={{ height: 10 }}></div>
    </div>
  );
}

export default EditAssociated;
