import PresenterInterface from "../PresenterInterface";
import { getTodayNumber } from "../../../utils/date_manipulation";

import { ProductOnDelivery } from "../../interactors/delivery/DeliveryInfoInteractor";
import DriverInteractor from "../../interactors/DriverInteractor";
import ProductInteractor from "../../interactors/ProductInteractor";
import DeliveryInteractor, {
  ProductToReturn,
} from "../../interactors/delivery/DeliveryInteractor";

export type DailyShippingProductData = {
  name: string;
  total: number;
  intolerant_count: number;
  have_any_intolerant: boolean;
  flavor_name: string;
  image_url: string;
};
export type DailyShippingData = {
  total_products: number;
  total_deliveries: number;
  total_products_to_return: number;
  products: DailyShippingProductData[];
};

export default class DailyShippingPresenter implements PresenterInterface {
  storage_path: string;
  constructor(private driver_id: string) {
    this.storage_path = "/daily_shipping/" + driver_id;
  }

  async getData(): Promise<DailyShippingData> {
    let associateds = await DriverInteractor.getDriverAssociatedsForWeekDay(
      this.driver_id,
      getTodayNumber()
    );

    let products_total: ProductOnDelivery[] = [];
    for (let associated of associateds) {
      let product = await DeliveryInteractor.shouldReceiveToday(associated.id);

      products_total = DeliveryInteractor.combineProductOnDelivery(
        product,
        products_total
      );
    }

    let product_to_return: ProductToReturn[] = [];
    for (let associated of associateds) {
      let products = await DeliveryInteractor.getReceivedToday(associated.id);
      product_to_return = DeliveryInteractor.combineProductToReturn(
        products,
        product_to_return
      );
    }

    let products_array: DailyShippingProductData[] =
      await this.getDailyShippingProductDataList(products_total);

    return {
      total_products: products_total.reduce((acc, prod) => acc + prod.total, 0),
      total_deliveries: associateds.length,
      total_products_to_return: product_to_return.reduce(
        (acc, prod) => acc + prod.total,
        0
      ),
      products: products_array,
    };
  }

  async getDailyShippingProductDataList(products: ProductOnDelivery[]) {
    let products_array: DailyShippingProductData[] = [];
    for (let product of products) {
      products_array.push({
        name: product.name,
        total: product.total,
        intolerant_count: product.intolerants,
        have_any_intolerant: product.intolerants !== 0,
        flavor_name: await ProductInteractor.getProductFlavorName(product),
        image_url: product.imageUrl,
      });
    }
    return products_array;
  }
}
