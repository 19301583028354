import React from 'react';
import 'react-calendar/dist/Calendar.css';

import { makeStyles } from '@material-ui/core/styles';
import CalendarioMural from '../../components/mural/CalendarioMural';
import { Divider } from '@material-ui/core';

import CarouselAvisos from "../../app/components/molecules/associated/CarouselMural"

const useStyles = makeStyles((theme) => ({
  spacing:  {
    paddingTop      : "10px" 
  },
  hText:{
    margin: theme.spacing(2),
  },
  paddingBottom: {
    marginBottom: "80px"
  }
}));


function Mural() {
  const classes     = useStyles();

  return   <div>
    <CarouselAvisos />
    <Divider/>
    <div className={classes.hText}>
      <h3>{"Calendário das entregas"}</h3>
    </div>
    <Divider variant="inset"/>
    <div className={classes.paddingBottom}>
      <CalendarioMural />
    </div>
  </div>
}

export default Mural;