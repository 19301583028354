import HomeIcon from "@material-ui/icons/Home";

import AdminHome from "../pages/admin/AdminHome";
import CreateDriver from "../pages/admin/CreateDriver";
import CreateAssociated from "../pages/admin/CreateAssociated";
import CreateProduct from "../pages/admin/CreateProduct";
import CreateAlert from "../pages/admin/CreateAlert";
import ProductsIndex from "../pages/admin/ProductsIndex";
import DriverIndex from "../pages/admin/DriverIndex";
import EditDriverDeliveries from "../pages/admin/EditDriverDeliveries";
import NeighborhoodIndex from "../pages/admin/NeighborhoodIndex";
import CreateNeighborhood from "../pages/admin/CreateNeighborhood";
import AdminChat from "../pages/admin/AdminChat";
import FlavorIndex from "../pages/admin/FlavorIndex";
import CreateFlavor from "../pages/admin/CreateFlavor";
import ReceiptIndex from "../pages/admin/ReceiptIndex";
import AssociatedIndex from "../pages/admin/AssociatedIndex";
import AcceptReceipt from "../pages/admin/AcceptReceipt";
import AlertIndex from "../pages/admin/AlertIndex";
import EditSystemAlert from "../pages/admin/EditSystemAlert";
import DriverOrder from "../pages/admin/DriverOrder";
import TermIndex from "../pages/admin/TermIndex";
import EditAssociated from "../pages/admin/associated/EditAssociated";


import IndexAssociatedReceipt from "../pages/admin/associated/IndexAssociatedReceipt";
import ShowDeliveries from "../pages/admin/associated/ShowDeliveries";
import AdminConfigs from "../pages/admin/config/AdminConfigs";
/**
 * Props para rotas:
 * {
 *  path:           caminho relativo a /admin
 *  component:      componente a ser renderizado
 *  backButton?:    booleano indicando se rota deve possuir botao de volta (faz o botao do drawer desaparecer)
 *  addButton?:     booleano
    logOutButton?:  boolean
 *  title?:         Titulo da pagina (Se deixado vazio, sera usado estado redux)
 *  drawer?: {
 *      show:       booleano indicando se rota deve aparecer no drawer
 *      icon:       Icone usado no drawer
 *      name:       Nome mostrado no drawer
 *  }
 *  dropDown?:{
 *    options: [
 *      {
 *        name: "string",
 *        redirectPath: "string"
 *      }
 *    ]
 *  }
 * }
 */
export default [
  {
    path: "/",
    component: AdminHome,
    title: "Mural",
    drawer: {
      show: true,
      name: "Mural",
    },
    logOutButton: true,
  },
  {
    path: "/associated/create",
    component: CreateAssociated,
    title: "Associado",
    backButton: true,
  },
  {
    path: "/associated/edit",
    component: EditAssociated,
    title: "Associado",
    dropDown: { options: [] },
    backButton: true,
  },
  {
    path: "/associated/show_deliveries",
    component: ShowDeliveries,
    title: "Historico de entregas",
    backButton: true,
  },
  {
    path: "/flavor/create",
    component: CreateFlavor,
    title: "Sabor",
    backButton: true,
  },
  {
    path: "/aviso/create",
    component: CreateAlert,
    title: "Novo Aviso",
    backButton: true,
  },
  {
    path: "/aviso",
    component: AlertIndex,
    title: "Ver Avisos",
    addButton: true,
    drawer: {
      show: true,
      name: "Avisos",
    },
  },
  {
    path: "/system_alert/edit/:id",
    component: EditSystemAlert,
    title: "Editar aviso do sistema",
    backButton: true,
  },
  {
    path: "/receipt/accept",
    component: AcceptReceipt,
    title: "Aceitar Recibo",
    backButton: true,
  },
  {
    path: "/product/create",
    component: CreateProduct,
    title: "Criar Produto",
    backButton: true,
  },
  {
    path: "/driver/create",
    component: CreateDriver,
    title: "Motorista",
    backButton: true,
  },
  {
    path: "/neighborhood/create",
    component: CreateNeighborhood,
    title: "Bairro",
    backButton: true,
  },
  {
    path: "/driver",
    component: DriverIndex,
    title: "Motoristas",
    addButton: true,
    drawer: {
      show: true,
      name: "Motoristas",
    },
  },
  {
    path: "/term",
    component: TermIndex,
    title: "Termo",
    addButton: false,
    drawer: {
      show: true,
      name: "Termo",
    },
  },
  {
    path: "/chat",
    component: AdminChat,
    title: "Chat",
    backButton: true,
  },
  {
    path: "/driver/deliveries",
    param: "/:id",
    component: EditDriverDeliveries,
    dropDown: { options: [] },
    backButton: true,
  },
  {
    path: "/product",
    component: ProductsIndex,
    title: "Produtos",
    drawer: {
      show: true,
      name: "Produtos",
    },
    addButton: true,
  },
  {
    path: "/associated_receipt",
    component: IndexAssociatedReceipt,
    title: "Recibos do Associado",
    backButton: true,
  },
  {
    path: "/product/edit/:id",
    component: CreateProduct,
    title: "Editar Produto",
    backButton: true,
  },
  {
    path: "/driver/route",
    component: DriverOrder,
    title: "Rota do dia",
    backButton: true,
  },
  {
    path: "/flavor",
    component: FlavorIndex,
    title: "Sabores",
    drawer: {
      show: true,
      name: "Sabores",
    },
    addButton: true,
  },
  {
    path: "/neighborhood",
    component: NeighborhoodIndex,
    title: "Bairros",
    drawer: {
      show: true,
      name: "Bairros",
    },
    addButton: true,
  },
  {
    path: "/associated",
    component: AssociatedIndex,
    title: "Associados",
    drawer: {
      show: true,
      name: "Associados",
    },
    addButton: true,
  },
  {
    path: "/receipt",
    component: ReceiptIndex,
    title: "Recibos",
    drawer: {
      show: true,
      name: "Recibos",
    },
  },
  {
    path: "/configs",
    component: AdminConfigs,
    title: "Configurações",
    drawer: {
      show: true,
      name: "Configurações Gerais",
    },
  },
];
